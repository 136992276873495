import {
  nameSchema,
  passwordSchema,
} from "../components/validations/validations";

export const CONFIG_VARS = Object.freeze({
  //inmobius: "https://admin.inmobiusinfinitylearn.com/api/v1", //prod
  inmobius: "https://inmobiusjob.devinfinitylearn.in/api/v1", //preprod
  // inmobius: "https://admin.inmobiusinfinitylearn.com/api/v1", //stagging
  //inmobius: "https://poc.inmobiusinfinitylearn.com/api/v1", //prod replica

  //inmobiusKey: "MtgZCn5NMS5lgp7m0NYSvB9Rb7jOVvKZcDm5cLgTWjVXdFG0Js", //prod
  inmobiusKey: "PMA4qXWFZ3uJstb8qtYWVjiFLgtAsm8UULe7cuhGydsdKcwJ", //preprod
  //inmobiusKey: "MtgZCn5NMS5lgp7m0NYSvB9Rb7jOVvKZcDm5cLgTWjVXdFG0Js", //prod replica

  //gatwayApi: "https://gatewayapi.infinitylearn.com/api/v1", // prod
  gatwayApi: "https://gatewayapi.devinfinitylearn.in/api/v1", //preprod
  //gatwayApi:"https://gatewaystaging.devinfinitylearn.in/api/v1", //stagging
  //gatwayApi: "https://pocgateway.inmobiusinfinitylearn.com/api/v1", // prod replica

  //gatwayApikey: "MtgZCn5NMS5lgp7m0NYSvB9Rb7jOVvKZcDm5cLgTWjVXdFG0Js", //prod
  gatwayApikey: "PMA4qXWFZ3uJstb8qtYWVjiFLgtAsm8UULe7cuhGydsdKcwJ", //preprod
  //gatwayApikey: "MtgZCn5NMS5lgp7m0NYSvB9Rb7jOVvKZcDm5cLgTWjVXdFG0Js",

  apiVersion: "v1",
  headers: {
    ["product-id"]: "1",
    ["tenant-id"]: "1",
  },
  roles: { "Inmboius Super Admin": [40], "Inmobius Rep": [33] }, //preprod
  //roles: { "Inmboius Super Admin": [37, 5], "Inmobius Rep": [36] }, //prod
  router: {
    1: "school",
    2: "user",
    3: "teacher",
  },
  otpUrl: "",
  countryApiKey: "a1JiWnJrVVp3YmxheTdybjFtbDVId2xjOU1UYUZ4dUZHMVNBMk5pZA==",
});
export const moduleConfig = {
  loginusers: [
    {
      name: "admission_number",
      type: "text",
      label: "Login Id",
      schema: nameSchema,
      mandatory: true,
    },
    {
      name: "password",
      type: "password",
      label: "Password",
      schema: passwordSchema,
      mandatory: true,
    },
  ],
};
